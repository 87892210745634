/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)
import $ from 'jquery';  



const hamburger = document.querySelector(".hamburger")
const bodyNoScroll = document.querySelector("body")
const close = document.querySelector(".hamburger.is-active")
const navMenu = document.querySelector("nav.menu")
hamburger.addEventListener ("click", openMenu )
function openMenu() {
  navMenu.classList.add("is-active")
  bodyNoScroll.classList.add("no-scroll")
}
close.addEventListener ("click", closeMenu )
function closeMenu() {
  navMenu.classList.remove("is-active")
  bodyNoScroll.classList.remove("no-scroll")
}



$('.format_dropdown, .classification_dropdown').on('change',function(e){
   requestParams();
  });

$("a.categories").on("click", function(e){
    e.preventDefault();
    var name = $(e.target).attr("name");
    if (name == "all") {
        $("a.categories").each(function(){
            if ($(this).attr("name") == "all"){
                $(this).addClass("active")
            } else {
                $(this).removeClass("active");
            }
        })
    } else {
        $(e.target).toggleClass("active");
        $("a[name='all']").removeClass("active");
    }
    requestParams();
    

})

function requestParams() {
    var category_ids = [];
    $("a.active").each(function(){
        category_ids.push($(this).attr("name"));
    })

    var format_id = $('.format_dropdown').find(":selected").val();
    var classification_id = $('.classification_dropdown').find(":selected").val();
    if (window.location.pathname.split("/").length == 3){
        var u = window.location.pathname.split("/")[2]
    } else if (window.location.pathname.split("/").length == 4){
        var u = window.location.pathname.split("/")[3]
    } else {
        var u = window.location.pathname.split("/")[4]
    }
    $.ajax({
        url: ''+ u,
        data: {
        format_id: format_id,
        classification_id: classification_id,
        category_ids: category_ids
    }
    }).done(function(data) {
       $('.entries').html(data);
    });
}